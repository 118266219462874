import '../common/theme/core';
import feather from 'feather-icons';
import * as bootstrap from 'bootstrap';
import docReady from '../common/docReady';

const toggleMenu = () => {
  // @ts-ignore
  document.getElementById('isToggle').classList.toggle('open');
  var isOpen = document.getElementById('navigation')
  // @ts-ignore
  if (isOpen.style.display === "block") {
    // @ts-ignore
    isOpen.style.display = "none";
  } else {
    // @ts-ignore
    isOpen.style.display = "block";
  }
};

const initPopover = () => {
  // tooltip
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // popover
  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  );
  popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });
}

docReady(() => {
  feather.replace();

  initPopover();

  const toggle = document.querySelector('#isToggle');
  if (toggle) {
    toggle.addEventListener('click', () => {
      toggleMenu();
    });
  }
});

// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
